import React from "react";
import PastProductionsContent from "../PastProductionsContent";
import Footer from "../Footer";

function PastProductions() {
  return (
    <>
      <PastProductionsContent />
      <br></br>
      <Footer />
    </>
  );
}

export default PastProductions;
