import React from "react";
import "./AboutContent.css";

function ProductionsContent() {
  return (
    <>
      <section className="hero">
        <div className="heading">
          <h1>Productions</h1>
          <h3>
            <a href="/productions">Current Productions</a> -{" "}
            <a href="/pastproductions">Past Productions</a>
          </h3>
        </div>
      </section>

      <br></br>

      <div className="container">
        <div className="hero-content">
          <h2>History Reheats Itself</h2>
          <h3>Sketch Comedy</h3>
          <h4>Judy's Beat Lounge @ Second City</h4>
          <p>Friday Nights @ 10:00pm</p>
          <p>March 7th - 28th</p>
          <button type="button" class="btn btn-outline-dark btn-rounded">
            <a href="https://www.secondcity.com/shows/chicago/history-reheats-itself-chi?fbclid=IwY2xjawIphwJleHRuA2FlbQIxMAABHSyIgomyDdR2QiXt4ZJaM0TdmEu7hLjhZtQA32Pwbiz0uGl15gXD76zg0g_aem_tRGQrIER7Cx8r9AaBpqiTA">
              TICKETS
            </a>
          </button>
        </div>
        <div className="hero-image-left">
          <img src="images/history.jpg" alt="history" />
        </div>
      </div>

      <br></br>

      <div className="container reverse-order">
        <div className="hero-image-left">
          <img src="images/propkids_march.jpg" alt="kittyandkellie" />
        </div>
        <div className="hero-content">
          <h2>The Propular Kidz</h2>
          <h3>Improv Comedy</h3>
          <h4>de Maat Theater @ Second City</h4>
          <p>Thursday Nights @ 7:30pm</p>
          <p>March 13th - March 27th</p>
          <button type="button" class="btn btn-outline-dark btn-rounded">
            <a href="https://www.secondcity.com/shows/chicago/the-propular-kidz-chi">
              TICKETS
            </a>
          </button>
        </div>
      </div>
    </>
  );
}

export default ProductionsContent;
